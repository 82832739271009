<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="card-header text-left fixed-card-title2">
        <v-col cols="10" class="align-center d-flex pa-0 mx-0">
          <v-toolbar-title>{{$t('edit-monitoring-plan')}}</v-toolbar-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
    </v-card-title>
	<v-card elevation="0">
      <v-card-text>
        <v-text-field
            v-model="patientActiveTreatmentPlan.tpName"
            :label= "$t('change-title')"
            dense
            outlined
            hide-details
        ></v-text-field>
        <v-textarea
            v-model="patientActiveTreatmentPlan.tpDescription"
            outlined
            :label= "$t('change-description')"
            hide-details
            class="pt-3"
            no-resize
        ></v-textarea>
      </v-card-text>

      <div v-for="item in treatmentPlansFromRepo" :key="item.id" >
        <div v-if="item.status === 1">
          <v-select
              v-model="selectedDevice"
              :items="item.treatmentPlanDevicesList"
              :item-text="$i18n.locale === 'en' ? 'deviceTypeTitle' : 'deviceTypeTitleEsp'"
              item-value="deviceTypeTitle"
              :label= "$t('select-device')"
              dense
              outlined
              class="px-4"
          ></v-select>
          <!--BLOOD GLUCOSE CARD-->
          <template v-if="selectedDevice">
            <div v-for="device in item.treatmentPlanDevicesList" :key="device.id" class="text-align3">
              <div class="custom-style4">
                <template v-if="device.deviceTypeTitle === 'Blood glucose' && selectedDevice === 'Blood glucose'">
                  <v-card class="fill-height custom-container3" elevation="8" outlined>
                    <div class="text-align2">
                      <img src="@/assets/icons/glucose_meter_black.svg" alt="Blood Pressure Icon" class="device-img">
                      <p class="margin-right10">{{ device.deviceTypeTitle }}</p>
                    </div>
                    <v-select
                        outlined
                        hide-details
                        dense
                        :items="measurementPeriodOptions"
                        v-model="selectedMeasurementPeriodGlucoseMeter"
                        required
                        append-icon="mdi-chevron-down"
                        class="custom-style2"
                    ></v-select>
                    <!--//selectedMeasurementCount-->
                    <v-row align="center">
                      <v-col v-for="index in bloodGlucoseInputCount" :key="index" class="margin-top30">
                        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="device['time' + index]"
                                :label="$t('time') + ' ' + index"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-card-text>
                            <v-time-picker
                                v-model="device['time' + index]"
                                @input="updateTimeForDevice('Blood glucose', index, $event)"
                            ></v-time-picker>
                          </v-card-text>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <p class="custom-text">{{$t('thresholds')}}</p>
                    <v-row class="mt-1">
                      <v-col>
                        <div class="custom-grid">
                          <div v-for="assignedDevice in getMedicalDevices" :key="assignedDevice.id">
                            <template v-if="assignedDevice.deviceType === 6">
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('blood-sugar-level-min')"
                                    class="width150mr"
                                    v-model="bloodSugarLevelMinGlucose"
                                    :error="isOutOfRange(bloodSugarLevelMinGlucose, 'bloodSugarLevelMinGlucose')"
                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('blood-sugar-level-max')"
                                    class="width150"
                                    v-model="bloodSugarLevelMaxGlucose"
                                    :error="isOutOfRange(bloodSugarLevelMaxGlucose, 'bloodSugarLevelMaxGlucose')"
                                ></v-text-field>
                              </v-row>
                            </template>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
                <template v-else-if="device.deviceTypeTitle === 'Oximeter' && selectedDevice === 'Oximeter'">
                  <v-card class="fill-height custom-container2" elevation="8" outlined>
                    <div class="text-align2">
                      <img src="@/assets/icons/oxymeter_black.svg" alt="Oximeter" class="device-img">
                      <p class="custom-margin">{{ device.deviceTypeTitle }}</p>
                    </div>
                    <v-select
                        outlined
                        hide-details
                        dense
                        :items="measurementPeriodOptions"
                        v-model="selectedMeasurementPeriodOximeter"
                        required
                        append-icon="mdi-chevron-down"
                        :placeholder="`${device.measurementsCount} times per day`"
                        class="custom-style5"
                    ></v-select>
                    <v-row align="start">
                      <v-col v-for="index in oximeterInputCount" :key="index" class="margin-top30">
                        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="device['time' + index]"
                                :label="$t('time') + ' ' + index"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-on="on"
                                width="100"
                                class="custom-margin2"
                            ></v-text-field>
                          </template>
                          <v-card-text>
                            <v-time-picker
                                v-model="device['time' + index]"
                                @input="updateTimeForDevice('Oximeter', index, $event)"
                            ></v-time-picker>
                          </v-card-text>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <p class="custom-text4">{{$t('thresholds')}}</p>
                    <v-row class="mt-1">
                      <v-col>
                        <div class="custom-grid">
                          <div v-for="assignedDevice in getMedicalDevices" :key="assignedDevice.id">
                            <template v-if="assignedDevice.deviceType === 2">
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    label="SpO2"
                                    class="width150mr"
                                    v-model="sp02"
                                    :error="isOutOfRange(sp02, 'spO2')"
                                ></v-text-field>
                              </v-row>
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('heart-beat-min')"
                                    class="width150mr"
                                    v-model="HeartBeatMinOximeter"
                                    :error="isOutOfRange(HeartBeatMinOximeter, 'heartBeatMinOximeter')"
                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('heart-beat-max')"
                                    class="width150mr"
                                    v-model="HeartBeatMaxOximeter"
                                    :error="isOutOfRange(HeartBeatMaxOximeter, 'heartBeatMaxOximeter')"
                                ></v-text-field>
                              </v-row>
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('pi-min')"
                                    class="width150mr"
                                    v-model="piMin"
                                    :error="isOutOfRange(piMin, 'piMin')"
                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('pi-min')"
                                    class="width150mr"
                                    v-model="piMax"
                                    :error="isOutOfRange(piMax, 'piMax')"
                                ></v-text-field>
                              </v-row>
                            </template>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
                <!--Blood PRESSURE CARD-->
                <template v-else-if="device.deviceTypeTitle === 'Blood pressure' && selectedDevice === 'Blood pressure'">
                  <v-card class="fill-height custom-container2" elevation="8" outlined>
                    <div class="text-align2">
                      <img src="@/assets/icons/blood_pressure_black.svg" alt="Blood Pressure Icon" class="device-img">
                      <p class="margin-right10">{{ device.deviceTypeTitle }}</p>
                    </div>
                    <v-select
                        outlined
                        hide-details
                        dense
                        :items="measurementPeriodOptions"
                        v-model="selectedMeasurementPeriodBloodPressure"
                        required
                        append-icon="mdi-chevron-down"
                        :placeholder="`${device.measurementsCount} times per day`"
                        class="custom-style2"
                    ></v-select>
                    <v-row align="center">
                      <v-col v-for="index in bloodPressureInputCount" :key="index" class="margin-top20">
                        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="device['time' + index]"
                                :label="$t('time') + ' ' + index"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-card-text>
                            <v-time-picker
                                v-model="device['time' + index]"
                                @input="updateTimeForDevice('Blood pressure', index, $event)"
                            ></v-time-picker>
                          </v-card-text>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <p class="custom-text2">{{$t('thresholds')}}</p>
                    <v-row class="mt-5">
                      <v-col>
                        <div class="custom-grid2">
                          <div v-for="assignedDevice in getMedicalDevices" :key="assignedDevice.id">
                            <template v-if="assignedDevice.deviceType === 3">  <v-row class="padding10">
                              <v-text-field
                                  outlined
                                  hide-details
                                  dense
                                  :label="$t('systolic-min')"
                                  inputmode="decimal"
                                  type="number"
                                  class="width150mr"
                                  v-model="systolicMin"
                                  :error="isOutOfRange(systolicMin, 'systolicMin')"
                              ></v-text-field>
                              <v-text-field
                                  outlined
                                  hide-details
                                  dense
                                  type="number"
                                  inputmode="decimal"
                                  :label="$t('systolic-max')"
                                  required
                                  class="width150"
                                  v-model="systolicMax"
                                  :error="isOutOfRange(systolicMax, 'systolicMax')"
                              ></v-text-field>
                            </v-row>
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('diastolic-min')"
                                    required
                                    class="width150mr"
                                    v-model="diastolicMin"
                                    :error="isOutOfRange(diastolicMin, 'diastolicMin')"
                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    hide-details
                                    type="number"
                                    inputmode="decimal"
                                    dense
                                    :label="$t('diastolic-max')"
                                    class="width150"
                                    v-model="diastolicMax"
                                    :error="isOutOfRange(diastolicMax, 'diastolicMax')"
                                ></v-text-field>
                              </v-row>
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    type="number"
                                    inputmode="decimal"
                                    dense
                                    :label="$t('heart-beat-min')"
                                    required
                                    class="width150mr"
                                    v-model="heartbeatMin"
                                    :step="0.01"
                                    :error="isOutOfRange(heartbeatMin, 'heartbeatMin')"
                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    hide-details
                                    type="number"
                                    inputmode="decimal"
                                    dense
                                    :label="$t('heart-beat-max')"
                                    required
                                    class="width150"
                                    v-model="heartbeatMax"
                                    :step="0.01"
                                    :error="isOutOfRange(heartbeatMax, 'heartbeatMax')"
                                ></v-text-field>
                              </v-row>
                            </template>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
				<!-- Thermometer -->
                <template v-else-if="device.deviceTypeTitle === 'Thermometer' && selectedDevice === 'Thermometer'">
                  <v-card class="fill-height custom-container2" elevation="8" outlined>
                    <div class="text-align2">
                      <img src="@/assets/icons/thermometer_black.svg" alt="Scale Icon" class="device-img">
                      <p class="margin-right10">{{ device.deviceTypeTitle }}</p>
                    </div>
                    <v-select
                        outlined
                        hide-details
                        dense
                        :items="measurementPeriodOptions"
                        v-model="selectedMeasurementPeriodThermometer"
                        required
                        append-icon="mdi-chevron-down"
                        class="flex-basis"
                    ></v-select>

                    <v-row align="center">
                      <v-col v-for="index in thermometerInputCount" :key="index" class="margin-top20">
                        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="device['time' + index]"
                                :label="$t('time') + ' ' + index"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-card-text>
                            <v-time-picker
                                v-model="device['time' + index]"
                                @input="updateTimeForDevice('Thermometer', index, $event)"
                            ></v-time-picker>
                          </v-card-text>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <p class="custom-text2">{{$t('thresholds')}}</p>
                    <v-row class="mt-1">
                      <v-col>
                        <div class="custom-grid3">
                          <div v-for="assignedDevice in getMedicalDevices" :key="assignedDevice.id">
                            <template v-if="assignedDevice.deviceType === 1">
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    :label="$t('temperature-min')"
                                    inputmode="decimal"
                                    type="number"
                                    class="width150mr"
                                    step="0.01"
                                    v-model="minTemperature"
                                    :error="isOutOfRange(minTemperature, 'minTemperature')"
                                >
                                </v-text-field>
                                <v-text-field
                                    outlined
                                    hide-details
                                    inputmode="decimal"
                                    type="number"
                                    dense
                                    :label="$t('temperature-max')"
                                    required
                                    class="width150"
                                    v-model="maxTemperature"
                                    step="0.01"
                                    :error="isOutOfRange(maxTemperature, 'maxTemperature')"

                                ></v-text-field>
                              </v-row>
                            </template>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
                <!--WEIGHT SCALE CARD-->
                <template v-else-if="device.deviceTypeTitle === 'Weight scale' && selectedDevice === 'Weight scale'">
                  <v-card class="fill-height custom-container2" elevation="8" outlined>
                    <div class="text-align2">
                      <img src="@/assets/icons/scale_black.svg" alt="Scale Icon" class="device-img">
                      <p class="margin-right10">{{ device.deviceTypeTitle }}</p>
                    </div>
                    <v-select
                        outlined
                        hide-details
                        dense
                        :items="measurementPeriodOptions"
                        v-model="selectedMeasurementPeriodWeight"
                        required
                        append-icon="mdi-chevron-down"
                        class="custom-style2"
                    ></v-select>
                    <!--//selectedMeasurementCount-->
                    <v-row align="center">
                      <v-col v-for="index in weightInputCount" :key="index" class="margin-top20">
                        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="device['time' + index]"
                                :label="$t('time') + ' ' + index"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-card-text>
                            <v-time-picker
                                v-model="device['time' + index]"
                                @input="updateTimeForDevice('Weight scale', index, $event)"
                            ></v-time-picker>
                          </v-card-text>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <p class="custom-text">{{$t('thresholds')}}</p>
                  </v-card>
                </template>
                <template v-else-if="device.deviceTypeTitle === 'Cholesterol Meter' && selectedDevice === 'Cholesterol Meter'">
                  <v-card class="fill-height custom-text2" elevation="2" outlined>
                    <div class="text-align2">
                      <img src="@/assets/icons/cholesterol_meter_black.svg" alt="Scale Icon" class="device-img">
                      <p class="margin-right10">{{ device.deviceTypeTitle }}</p>
                    </div>
                    <v-select
                        outlined
                        hide-details
                        dense
                        :items="measurementPeriodOptions"
                        v-model="selectedMeasurementPeriodCholesterol"
                        required
                        append-icon="mdi-chevron-down"
                        class="custom-style2"
                    ></v-select>
                    <!--//selectedMeasurementCount-->
                    <v-row align="center">
                      <v-col v-for="index in cholesterolInputCount" :key="index" class="margin-top20">
                        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="device['time' + index]"
                                :label="$t('time') + ' ' + index"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-on="on"
                            ></v-text-field>
                          </template>
                          <v-card-text>
                            <v-time-picker
                                v-model="device['time' + index]"
                                @input="updateTimeForDevice('Cholesterol Meter', index, $event)"
                            ></v-time-picker>
                          </v-card-text>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <!--This is when we are changing the time .-->
                    <p class="custom-text2">{{$t('thresholds')}}</p>
                    <v-row class="mt-1">
                      <v-col>
                          <div v-for="assignedDevice in getMedicalDevices" :key="assignedDevice.id">
                            <template v-if="assignedDevice.deviceType === 7">
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('blood-sugar-level-min')"
                                    required
                                    class="width150mr"
                                    v-model="bloodSugarLevelMin"
                                    :step="0.01"
                                    :error="isOutOfRange(bloodSugarLevelMin, 'bloodSugarLevelMin')"

                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('blood-sugar-level-min')"
                                    required
                                    class="width150mr"
                                    v-model="bloodSugarLevelMax"
                                    :step="0.01"
                                    :error="isOutOfRange(bloodSugarLevelMax, 'bloodSugarLevelMax')"

                                ></v-text-field>
                              </v-row>
                              <v-row class="padding10">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('cholesterol-min')"
                                    required
                                    class="width150mr"
                                    v-model="cholesterolLevelMin"
                                    :step="0.01"
                                    :error="isOutOfRange(cholesterolLevelMin, 'cholesterolLevelMin')"

                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    type="number"
                                    inputmode="decimal"
                                    :label="$t('cholesterol-max')"
                                    required
                                    class="width150mr"
                                    v-model="cholesterolLevelMax"
                                    :step="0.01"
                                    :error="isOutOfRange(cholesterolLevelMax, 'cholesterolLevelMax')"
                                ></v-text-field>
                              </v-row>
                            </template>
                          </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>

		<div>
			<div v-if="isForMentalHealth" class="d-flex pl-5 mt-n3">
				<p class="font-weight-bold font-size15 color-black" >{{$t('wellness-assessments')}}</p>
			</div>
			<div v-if="isForMentalHealth" class="px-4">
				<v-btn class="full-width" color="transparent" @click="openWellnessCard">+ {{$t('assessments')}}</v-btn>
			</div>
			<v-col class="px-4">
				<div class="full-width" v-if="openWellness">
					<v-card class="custom-container" elevation="5">
					<div class="flex-container">
						<div class="text-align">
						<img :src="wellnessAssessmentBlack" class="small-height">
						<div class="custom-text3">{{$t('wellness-assessments')}}</div>
						</div>
						<v-icon class="mdi mdi-delete cursor" color="main_black"></v-icon>
					</div>
					<div>
						<v-col cols="12">
							<v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field :label="$t('start-date')" v-model="date" prepend-icon="mdi-calendar" readonly hide-details dense outlined v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker range v-model="date" no-title scrollable >
									<v-spacer></v-spacer>
									<v-btn text class="color-primary" @click="menu = false">Cancel</v-btn>
									<v-btn text class="color-primary" @click="$refs.menu.save(date)">OK</v-btn>
								</v-date-picker>
							</v-menu>
						</v-col>

					</div>

					<div class="small-padding2">
						<v-select outlined dense :label="$t('Assessments')" :items="surveyTemplates" item-text="templateName" item-value="id" v-model="selectedTemplate"></v-select>
						<v-select outlined dense :label="$t('measurement-period')" class="margin-top-10" :items="surveyPeriods" v-model="selectedPeriod" item-text="shortDescription" item-value="lowValue">
						</v-select>
						<v-card-actions>
						<v-btn @click="assignSurveyTemplatetoPatient"  class="ml-auto save-button-style">{{ $t("save") }}</v-btn>
						<!-- Add other action buttons or icons as needed -->
						</v-card-actions>
					</div>
					</v-card>
				</div>
			</v-col>
		</div>

      <v-card-actions class="fixed-card-actions">
        <v-row class="mb-0">
          <v-col cols="6">
            <v-btn
                @click="closeDrawer"
                elevation="0"
                class="cancel-button-style capitalize-text"
            >
              {{$t('cancel')}}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                @click="saveMonitoringPlan"
                class="save-button-style"
            >
              {{$t('save')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { closeWhite, closeBlack, arrowRight, wellnessAssessmentBlack } from '@/assets';

export default {
	props: ['dialog', 'data', 'deniedPatients', 'item', 'idPatient', 'computedPatientId'],
	item: {
		deviceDefaultValuesJson: '',
	},
	data () {
		return {
			arrowRight: arrowRight,
			weightscaleTimeChanges: false,
			isEnabled: false,
			heartbeatMinExtreme: '',
			changedTitleDescription: false,
			systolicMinExtreme: '',
			systolicMaxExtreme: '',
			temperatureTimeError: false,
			temperatureProcessError: false,
			temperatureChangeError: false,
			changesMade: false,
			oximeterChanges: false,
			oximeterTimeChanges: false,
			cholesterolChanges: false,
			cholesterolTimeChanges: false,
			temperatureChanges: false,
			temperatureTimeChanges: false,
			bloodpressureChanges: false,
			bloodpressureTimeChanges: false,
			bloodglucoseTimeChanges: false,
			bloodglucoseChanges: false,
			temperatureFailed: false,
			diastolicMaxExtreme: '',
			Sp02Extreme: '',
			heartbeatMinOximeterExtreme: '',
			heartbeatMaxOximeterExtreme: '',
			piMinExtreme: '',
			piMaxExtreme: '',
			diastolicMinExtreme: '',
			heartbeatMaxExtreme: '',
			bloodSugarMinGlucoseExtreme: '',
			bloodSugarMaxGlucoseExtreme: '',
			bloodSugarMinCholesterolExtreme: '',
			bloodSugarMaxCholesterolExtreme: '',
			cholesterolLevelMinExtreme: '',
			cholesterolLevelMaxExtreme: '',
			heartbeatMinextreme: '',
			thermometerInputCount: '',
			weightInputCount: '',
			bloodPressureInputCount: '',
			cholesterolInputCount: '',
			oximeterInputCount: '',
			bloodGlucoseInputCount: '',
			minTemperature: '',
			maxTemperature: '',
			bloodSugarLevelMin: '',
			bloodSugarLevelMax: '',
			diastolicMin: '',
			diastolicMax: '',
			minTemperatureExtremeValue: '',
			maxTemperatureExtremeValue: '',
			bloodSugarLevelMinGlucose: '',
			bloodSugarLevelMaxGlucose: '',
			weightScaleMin: '',
			weightScaleMax: '',
			measurementCountToOptionMap: {
				1: 'onePerDay',
				2: 'twicePerDay',
				3: 'threeTimesPerDay',
				4: 'fourTimesPerDay',
			},
			heartbeatMax: '',
			closeBlack: closeBlack,
			selectedMeasurementPeriod: '',
			heartbeatMin: '',
			sp02: '',
			HeartBeatMinOximeter: '',
			HeartBeatMaxOximeter: '',
			piMin: '',
			piMax: '',
			cholesterolLevelMin: '',
			cholesterolLevelMax: '',
			systolicMin: '',
			systolicMax: '',
			assignedDevice: '',
			CloseWhite: closeWhite,
			device: {
				time1: '',
				time2: '',
				time3: '',
				time4: '',
			},
			devices: {
				'Weight scale': {
					time1: '',
					time2: '',
					time3: '',
					time4: '',
				},
				'Cholesterol Meter': {
					time1: '',
					time2: '',
					time3: '',
					time4: '',
				},
				Oximeter: {
					time1: '',
					time2: '',
					time3: '',
					time4: '',
				},
				'Blood pressure': {
					time1: '',
					time2: '',
					time3: '',
					time4: '',
				},

				Thermometer: {
					time1: '',
					time2: '',
					time3: '',
					time4: '',
				},

				'Blood glucose': {
					time1: '',
					time2: '',
					time3: '',
					time4: '',
				},
			},
			treatmentPlans: [],
			patientDevices: [],
			treatmentPlanDevicesList: [],
			selectedMeasurementPeriodThermometer: null,
			selectedMeasurementPeriodWeight: null,
			selectedMeasurementPeriodCholesterol: null,
			selectedMeasurementPeriodBloodPressure: null,
			selectedMeasurementPeriodGlucoseMeter: null,
			selectedMeasurementPeriodOximeter: null,
			measurementPeriodOptions: [
				{ text: this.$t('one-time-per-day'), value: 'onePerDay' },
				{ text: this.$t('two-time-per-day'), value: 'twicePerDay' },
				{ text: this.$t('three-time-per-day'), value: 'threeTimesPerDay' },
				{ text: this.$t('four-time-per-day'), value: 'fourTimesPerDay' },
			],
			selectedDevice: '',
			openWellness: false,
			date: null,
			selectedTemplate: null,
			selectedPeriod: null,
			menu: false,
      wellnessAssessmentBlack: wellnessAssessmentBlack,
		};
	},
	async mounted () {
		this.loadData();
		await this.getMentalHealthSurveyTemplates();
	},
	computed: {
		...mapState({
			detailedUser: (state) => state.users.detailedUser,
			treatmentPlansFromRepo: (state) => state.treatmentplan.treamentPlans,
			patientActiveTreatmentPlan: (state) => state.medicaldevice.patientActiveTreatmentPlan,
			medicalDevicesList: (state) => state.medicaldevice.medicalDevicesList,
			surveyById: (state) => state.mentalhealth.survey,
			authUserData: (state) => state.authentication.userData,
			surveyTemplates: (state) => state.mentalhealth.surveys,
			surveyPeriods: (state) => state.verifiers.surveyRecurrence,
		}),
		...mapGetters({
			getMedicalDevices: 'medicaldevice/getMedicalDevices',
			getMedicalDevicesList: 'medicaldevice/getMedicalDevicesList',
		}),
		surveyData () {
			return this.surveyById ? this.surveyById : null;
		},
		isForMentalHealth () {
			return this.detailedUser?.userData?.patientMetadata?.mentalHealth;
		},
	},

	watch: {
		'device.time1': function (newValue, oldValue) {
			if (this.selectedDevice === 'Cholesterol Meter') {
				this.handleTimeChange('Cholesterol Meter', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Oximeter') {
				this.handleTimeChange('Oximeter', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Thermometer') {
				this.handleTimeChange('Thermometer', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Blood pressure') {
				this.handleTimeChange('Blood pressure', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Blood glucose') {
				this.handleTimeChange('Blood glucose', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Weight scale') {
				this.handleTimeChange('Weight scale', newValue, oldValue);
				this.isEnabled = true;
			}
		},
		'device.time2': function (newValue, oldValue) {
			if (this.selectedDevice === 'Cholesterol Meter') {
				this.handleTimeChange('Cholesterol Meter', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Oximeter') {
				this.handleTimeChange('Oximeter', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Thermometer') {
				this.handleTimeChange('Thermometer', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Blood pressure') {
				this.handleTimeChange('Blood pressure', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Blood glucose') {
				this.handleTimeChange('Blood glucose', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Weight scale') {
				this.handleTimeChange('Weight scale', newValue, oldValue);
				this.isEnabled = true;
			}
		},
		'device.time3': function (newValue, oldValue) {
			if (this.selectedDevice === 'Cholesterol Meter') {
				this.handleTimeChange('Cholesterol Meter', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Oximeter') {
				this.handleTimeChange('Oximeter', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Thermometer') {
				this.handleTimeChange('Thermometer', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Blood pressure') {
				this.handleTimeChange('Blood pressure', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Blood glucose') {
				this.handleTimeChange('Blood glucose', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Weight scale') {
				this.handleTimeChange('Weight scale', newValue, oldValue);
				this.isEnabled = true;
			}
		},
		'device.time4': function (newValue, oldValue) {
			if (this.selectedDevice === 'Cholesterol Meter') {
				this.handleTimeChange('Cholesterol Meter', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Oximeter') {
				this.handleTimeChange('Oximeter', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Thermometer') {
				this.handleTimeChange('Thermometer', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Blood pressure') {
				this.handleTimeChange('Blood pressure', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Blood glucose') {
				this.handleTimeChange('Blood glucose', newValue, oldValue);
				this.isEnabled = true;
			}
			if (this.selectedDevice === 'Weight scale') {
				this.handleTimeChange('Weight scale', newValue, oldValue);
				this.isEnabled = true;
			}
		},
		minTemperature (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialminTemperature) {
				this.temperatureChanges = true;
				this.isEnabled = true;
			}
		},
		maxTemperature (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialmaxTemperature) {
				this.temperatureChanges = true;
				this.isEnabled = true;
			}
		},
		bloodSugarLevelMax (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialbloodSugarLevelMax) {
				this.cholesterolChanges = true;
				this.isEnabled = true;
			}
		},
		bloodSugarLevelMin (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialbloodSugarLevelMin) {
				this.cholesterolChanges = true;
				this.isEnabled = true;
			}
		},
		cholesterolLevelMax (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialcholesterolLevelMax) {
				this.cholesterolChanges = true;
				this.isEnabled = true;
			}
		},
		cholesterolLevelMin (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialcholesterolLevelMin) {
				this.cholesterolChanges = true;
				this.isEnabled = true;
			}
		},
		sp02 (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialSp02) {
				this.oximeterChanges = true;
				this.isEnabled = true;
			}
		},
		HeartBeatMinOximeter (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialHeartBeatMinOximeter) {
				this.oximeterChanges = true;
				this.isEnabled = true;
			}
		},
		HeartBeatMaxOximeter (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialHeartBeatMaxOximeter) {
				this.oximeterChanges = true;
				this.isEnabled = true;
			}
		},
		piMin (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialPiMin) {
				this.oximeterChanges = true;
				this.isEnabled = true;
			}
		},
		piMax (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialPiMax) {
				this.oximeterChanges = true;
				this.isEnabled = true;
			}
		},
		systolicMin (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialsystolicMin) {
				this.bloodpressureChanges = true;
				this.isEnabled = true;
			}
		},

		systolicMax (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialsystolicMax) {
				this.bloodpressureChanges = true;
				this.isEnabled = true;
			}
		},

		diastolicMin (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialdiastolicMin) {
				this.bloodpressureChanges = true;
				this.isEnabled = true;
			}
		},

		diastolicMax (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialdiastolicMax) {
				this.bloodpressureChanges = true;
				this.isEnabled = true;
			}
		},

		heartbeatMin (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialheartbeatMin) {
				this.bloodpressureChanges = true;
				this.isEnabled = true;
			}
		},
		heartbeatMax (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialheartbeatMax) {
				this.bloodpressureChanges = true;
				this.isEnabled = true;
			}
		},

		bloodSugarLevelMaxGlucose (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialbloodSugarLevelMaxGlucose) {
				this.bloodglucoseChanges = true;
				this.isEnabled = true;
			}
		},
		bloodSugarLevelMinGlucose (newValue, oldValue) {
			if (newValue !== oldValue && newValue !== this.initialbloodSugarLevelMinGlucose) {
				this.bloodglucoseChanges = true;
				this.isEnabled = true;
			}
		},

		'patientActiveTreatmentPlan.tpName': function (newValue, oldValue) {
			if (newValue !== oldValue) {
				this.changedTitleDescription = true;
				this.isEnabled = true;
			}
		},
		'patientActiveTreatmentPlan.tpDescription': function (newValue, oldValue) {
			if (newValue !== oldValue) {
				this.changedTitleDescription = true;
				this.isEnabled = true;
			}
		},

		thermometerInputCount (newValue) {
			this.selectedMeasurementPeriodThermometer = this.measurementCountToOptionMap[newValue];
		},
		weightInputCount (newValue) {
			this.selectedMeasurementPeriodWeight = this.measurementCountToOptionMap[newValue];
		},
		cholesterolInputCount (newValue) {
			this.selectedMeasurementPeriodCholesterol = this.measurementCountToOptionMap[newValue];
		},
		bloodPressureInputCount (newValue) {
			this.selectedMeasurementPeriodBloodPressure = this.measurementCountToOptionMap[newValue];
		},
		bloodGlucoseInputCount (newValue) {
			this.selectedMeasurementPeriodGlucoseMeter = this.measurementCountToOptionMap[newValue];
		},
		oximeterInputCount (newValue) {
			this.selectedMeasurementPeriodOximeter = this.measurementCountToOptionMap[newValue];
		},

		selectedMeasurementPeriodThermometer (newPeriod) {
			switch (newPeriod) {
			case 'onePerDay':
				this.thermometerInputCount = 1;
				break;
			case 'twicePerDay':
				this.thermometerInputCount = 2;
				break;
			case 'threeTimesPerDay':
				this.thermometerInputCount = 3;
				break;
			case 'fourTimesPerDay':
				this.thermometerInputCount = 4;
				break;
			default:
				this.thermometerInputCount = 1;
			}
		},
		selectedMeasurementPeriodWeight (newPeriod) {
			switch (newPeriod) {
			case 'onePerDay':
				this.weightInputCount = 1;
				break;
			case 'twicePerDay':
				this.weightInputCount = 2;
				break;
			case 'threeTimesPerDay':
				this.weightInputCount = 3;
				break;
			case 'fourTimesPerDay':
				this.weightInputCount = 4;
				break;
			default:
				this.weightInputCount = 1;
			}
		},
		selectedMeasurementPeriodBloodPressure (newPeriod) {
			switch (newPeriod) {
			case 'onePerDay':
				this.bloodPressureInputCount = 1;
				break;
			case 'twicePerDay':
				this.bloodPressureInputCount = 2;
				break;
			case 'threeTimesPerDay':
				this.bloodPressureInputCount = 3;
				break;
			case 'fourTimesPerDay':
				this.bloodPressureInputCount = 4;
				break;
			default:
				this.bloodPressureInputCount = 1;
			}
		},
		selectedMeasurementPeriodCholesterol (newPeriod) {
			switch (newPeriod) {
			case 'onePerDay':
				this.cholesterolInputCount = 1;
				break;
			case 'twicePerDay':
				this.cholesterolInputCount = 2;
				break;
			case 'threeTimesPerDay':
				this.cholesterolInputCount = 3;
				break;
			case 'fourTimesPerDay':
				this.cholesterolInputCount = 4;
				break;
			default:
				this.cholesterolInputCount = 1;
			}
		},
		selectedMeasurementPeriodGlucoseMeter (newPeriod) {
			switch (newPeriod) {
			case 'onePerDay':
				this.bloodGlucoseInputCount = 1;
				break;
			case 'twicePerDay':
				this.bloodGlucoseInputCount = 2;
				break;
			case 'threeTimesPerDay':
				this.bloodGlucoseInputCount = 3;
				break;
			case 'fourTimesPerDay':
				this.bloodGlucoseInputCount = 4;
				break;
			default:
				this.bloodGlucoseInputCount = 1;
			}
		},
		selectedMeasurementPeriodOximeter (newPeriod) {
			switch (newPeriod) {
			case 'onePerDay':
				this.oximeterInputCount = 1;
				break;
			case 'twicePerDay':
				this.oximeterInputCount = 2;
				break;
			case 'threeTimesPerDay':
				this.oximeterInputCount = 3;
				break;
			case 'fourTimesPerDay':
				this.oximeterInputCount = 4;
				break;
			default:
				this.oximeterInputCount = 1;
			}
		},
	},
	methods: {
    // updateSurveyTemplate () {
    //   this.assessmentName = this.surveyData.templateName;
    //   this.description = this.surveyData.templateDescription;
    //
    //   const selectedMentalHealthCategory = this.getMentalHealthCategoryForForm.find(category =>
    //       category.longDescription === this.surveyData.mentalHealthCategoryTitle,
    //   );
    //   this.mentalHealthCategoryId = selectedMentalHealthCategory ? selectedMentalHealthCategory.lowValue : null;
    //   const object = {
    //     templateName: this.assessmentName,
    //     mentalHealthCategoryId: this.mentalHealthCategoryId,
    //     templateDescription: this.description,
    //     id: this.surveyData.id,
    //     status: this.surveyData.status ? 1 : 0,
    //   };
    //   this.$store.dispatch('mentalhealth/updateSurveyTemplate', object).then(res => {
    //     // Handle success or any other actions you need to take
    //     this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
    //     this.surveyId = res.id;
    //   });
    //   this.closeDrawer();
    // },

		updateTimeForDevice (deviceType, index, newValue) {
			this.device['time' + index] = newValue;
			this.devices[deviceType]['time' + index] = newValue;
		},
		formatNumber (value) {
			const parsedValue = parseFloat(value);
			if (!isNaN(parsedValue)) {
				if (parsedValue % 1 === 0) {
					return parsedValue.toString();
				} else {
					return parsedValue.toFixed(1);
				}
			} else {
				return '';
			}
		},

		// Validation
		isOutOfRange (value, type) {
			const validationRules = {
				systolicMin: [this.systolicMinExtreme, this.systolicMaxExtreme],
				systolicMax: [this.systolicMinExtreme, this.systolicMaxExtreme],
				diastolicMin: [this.diastolicMinExtreme, this.diastolicMaxExtreme],
				diastolicMax: [this.diastolicMinExtreme, this.diastolicMaxExtreme],
				heartbeatMin: [this.heartbeatMinExtreme, this.heartbeatMaxExtreme],
				heartbeatMax: [this.heartbeatMinExtreme, this.heartbeatMaxExtreme],
				minTemperature: [this.minTemperatureExtremeValue, this.maxTemperatureExtremeValue],
				maxTemperature: [this.minTemperatureExtremeValue, this.maxTemperatureExtremeValue],
				spO2: [this.Sp02Extreme, 150],
				heartBeatMinOximeter: [this.heartbeatMinOximeterExtreme, this.heartbeatMaxOximeterExtreme],
				heartBeatMaxOximeter: [this.heartbeatMinOximeterExtreme, this.heartbeatMaxOximeterExtreme],
				piMin: [this.piMinExtreme, this.piMaxExtreme],
				piMax: [this.piMinExtreme, this.piMaxExtreme],
				bloodSugarLevelMinGlucose: [this.bloodSugarMinGlucoseExtreme, this.bloodSugarMaxGlucoseExtreme],
				bloodSugarLevelMaxGlucose: [this.bloodSugarMinGlucoseExtreme, this.bloodSugarMaxGlucoseExtreme],
				cholesterolLevelMin: [this.cholesterolLevelMinExtreme, this.cholesterolLevelMaxExtreme],
				cholesterolLevelMax: [this.cholesterolLevelMinExtreme, this.cholesterolLevelMaxExtreme],
				bloodSugarLevelMin: [this.bloodSugarMinCholesterolExtreme, this.bloodSugarMaxCholesterolExtreme],
				bloodSugarLevelMax: [this.bloodSugarMinCholesterolExtreme, this.bloodSugarMaxCholesterolExtreme],
			};

			const [minExtreme, maxExtreme] = validationRules[type];

			if (value === '' || value < minExtreme || value > maxExtreme) {
				this.isEnabled = false;
				return true;
			}
			return false; // no error
		},
		handleTimeChange (deviceType, newValue, oldValue) {
			const device = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(device => device.deviceTypeTitle === deviceType);
			if (device && newValue !== oldValue) {
				if (deviceType === 'Cholesterol Meter') {
					this.cholesterolTimeChanges = true;
				}
				if (deviceType === 'Weight scale') {
					this.weightscaleTimeChanges = true;
				} else if (deviceType === 'Oximeter') {
					this.oximeterTimeChanges = true;
				} else if (deviceType === 'Thermometer') {
					this.temperatureTimeChanges = true;
				} else if (deviceType === 'Blood pressure') {
					this.bloodpressureTimeChanges = true;
				} else if (deviceType === 'Blood glucose') {
					this.bloodglucoseTimeChanges = true;
				}
			}
		},
		closeDrawer () {
			this.selectedDevice = '';
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
      this.openWellness = false;
		},
		parseJson (jsonString) {
			return JSON.parse(jsonString);
		},
		selectDevice (device) {
			this.selectedDevice = device;
		},
		loadData () {
			this.loadDevicesForPatient();
		},
		loadDevicesForPatient () {
			this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id).then((patientDevices) => {
				const patientsData = [];
				for (let i = 0; i < this.medicalDevicesList.length; i++) {
					const patientData = {};
					const thermometerDevice = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(device => device.deviceTypeTitle === 'Thermometer');
					if (thermometerDevice) {
						this.thermometerInputCount = thermometerDevice.measurementsCount || 1;
					} else {
						this.thermometerInputCount = 1;
					}

					const WeightDevice = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(device => device.deviceTypeTitle === 'Weight scale');
					if (WeightDevice) {
						this.weightInputCount = WeightDevice.measurementsCount || 1;
					} else {
						this.weightInputCount = 1;
					}

					const CholesterolDevice = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(device => device.deviceTypeTitle === 'Cholesterol Meter');
					if (CholesterolDevice) {
						this.cholesterolInputCount = CholesterolDevice.measurementsCount || 1;
					} else {
						this.cholesterolInputCount = 1;
					}

					const BloodPressureDevice = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(device => device.deviceTypeTitle === 'Blood pressure');
					if (BloodPressureDevice) {
						this.bloodPressureInputCount = BloodPressureDevice.measurementsCount || 1;
					} else {
						this.bloodPressureInputCount = 1;
					}

					// Find the oximeter device and set its input count
					const oximeterDevice = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(device => device.deviceTypeTitle === 'Oximeter');
					if (oximeterDevice) {
						this.oximeterInputCount = oximeterDevice.measurementsCount || 1;
					} else {
						this.oximeterInputCount = 1;
					}

					const bloodGlucoseDevice = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(device => device.deviceTypeTitle === 'Blood glucose');
					if (bloodGlucoseDevice) {
						this.bloodGlucoseInputCount = bloodGlucoseDevice.measurementsCount || 1;
					} else {
						this.bloodGlucoseInputCount = 1;
					}

					const bloodSugarDevice = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 6);
					if (bloodSugarDevice) {
						const bloodSugarDefaultValues = this.parseJson(bloodSugarDevice.deviceDefaultValuesJson);
						this.bloodSugarLevelMinGlucose = bloodSugarDefaultValues.BloodSugarLevelMin;
						this.bloodSugarLevelMaxGlucose = bloodSugarDefaultValues.BloodSugarLevelMax;

						this.initialbloodSugarLevelMinGlucose = this.bloodSugarLevelMinGlucose;
						this.initialbloodSugarLevelMaxGlucose = this.bloodSugarLevelMaxGlucose;
					}
					const bloodSugarDeviceExtreme = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 6);
					if (bloodSugarDeviceExtreme) {
						const bloodSugarExtremeValues = this.parseJson(bloodSugarDevice.deviceDefaultExtremeValuesJson);
						this.bloodSugarMinGlucoseExtreme = bloodSugarExtremeValues.BloodSugarLevelMin;
						this.bloodSugarMaxGlucoseExtreme = bloodSugarExtremeValues.BloodSugarLevelMax;
					}
					const OximeterDevice = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 2);
					if (OximeterDevice) {
						const OximeterDefaultValues = this.parseJson(OximeterDevice.deviceDefaultValuesJson);
						this.sp02 = OximeterDefaultValues.SpO2;
						this.HeartBeatMinOximeter = OximeterDefaultValues.HeartBeatMin;
						this.HeartBeatMaxOximeter = OximeterDefaultValues.HeartBeatMax;
						this.piMin = OximeterDefaultValues.PiMin;
						this.piMax = OximeterDefaultValues.PiMax;

						this.initialSp02 = this.sp02;
						this.initialHeartBeatMinOximeter = this.HeartBeatMinOximeter;
						this.initialHeartBeatMaxOximeter = this.HeartBeatMaxOximeter;
						this.initialPiMin = this.piMin;
						this.initialPiMax = this.piMax;
					}
					const OximeterDeviceExtreme = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 2);
					if (OximeterDeviceExtreme) {
						const OximeterExtremeValues = this.parseJson(OximeterDevice.deviceDefaultExtremeValuesJson);
						this.Sp02Extreme = OximeterExtremeValues.SpO2;
						this.heartbeatMaxOximeterExtreme = OximeterExtremeValues.HeartBeatMax;
						this.heartbeatMinOximeterExtreme = OximeterExtremeValues.HeartBeatMin;
						this.piMinExtreme = OximeterExtremeValues.PiMin;
						this.piMaxExtreme = OximeterExtremeValues.PiMax;
					}
					// Blood Pressure
					const bloodPressureDevice = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 3);
					if (bloodPressureDevice) {
						const bloodPressureDefaultValues = this.parseJson(bloodPressureDevice.deviceDefaultValuesJson);
						this.systolicMin = bloodPressureDefaultValues.SystolicMin;
						this.systolicMax = bloodPressureDefaultValues.SystolicMax;
						this.diastolicMin = bloodPressureDefaultValues.DiastolicMin;
						this.diastolicMax = bloodPressureDefaultValues.DiastolicMax;
						this.heartbeatMin = bloodPressureDefaultValues.HeartBeatMin;
						this.heartbeatMax = bloodPressureDefaultValues.HeartBeatMax;

						this.initialsystolicMin = this.systolicMin;
						this.initialsystolicMax = this.systolicMax;
						this.initialdiastolicMin = this.diastolicMin;
						this.initialdiastolicMax = this.diastolicMax;
						this.initialheartbeatMin = this.heartbeatMin;
						this.initialheartbeatMax = this.heartbeatMax;
					}
					const extremeBloodPressure = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 3);
					if (extremeBloodPressure) {
						const bloodPressureExtremeValues = this.parseJson(bloodPressureDevice.deviceDefaultExtremeValuesJson);
						this.systolicMinExtreme = bloodPressureExtremeValues.SystolicMin;
						this.systolicMaxExtreme = bloodPressureExtremeValues.SystolicMax;
						this.diastolicMinExtreme = bloodPressureExtremeValues.DiastolicMin;
						this.diastolicMaxExtreme = bloodPressureExtremeValues.DiastolicMax;
						this.heartbeatMinExtreme = bloodPressureExtremeValues.HeartBeatMin;
						this.heartbeatMaxExtreme = bloodPressureExtremeValues.HeartBeatMax;
					}
					const cholesterolDevice = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 7);

					if (cholesterolDevice) {
						const cholesterolDefaultValues = this.parseJson(cholesterolDevice.deviceDefaultValuesJson);
						this.bloodSugarLevelMax = cholesterolDefaultValues.BloodSugarLevelMax;
						this.bloodSugarLevelMin = cholesterolDefaultValues.BloodSugarLevelMin;
						this.cholesterolLevelMin = cholesterolDefaultValues.CholesterolLevelMin;
						this.cholesterolLevelMax = cholesterolDefaultValues.CholesterolLevelMax;

						this.initialbloodSugarLevelMax = this.bloodSugarLevelMax;
						this.initialbloodSugarLevelMin = this.bloodSugarLevelMin;
						this.initialcholesterolLevelMin = this.cholesterolLevelMin;
						this.initialcholesterolLevelMax = this.cholesterolLevelMax;
					}

					const cholesterolDeviceExtreme = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 7);
					if (cholesterolDeviceExtreme) {
						const cholesterolExtremeValues = this.parseJson(cholesterolDeviceExtreme.deviceDefaultExtremeValuesJson);
						this.bloodSugarMaxCholesterolExtreme = cholesterolExtremeValues.BloodSugarLevelMax;
						this.bloodSugarMinCholesterolExtreme = cholesterolExtremeValues.BloodSugarLevelMin;
						this.cholesterolLevelMinExtreme = cholesterolExtremeValues.CholesterolLevelMin;
						this.cholesterolLevelMaxExtreme = cholesterolExtremeValues.CholesterolLevelMax;
					}

					const TemperatureDevice = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 1);

					if (TemperatureDevice) {
						const temperatureDefaultValues = this.parseJson(TemperatureDevice.deviceDefaultValuesJson);
						this.minTemperature = temperatureDefaultValues.MinTemperature;
						this.maxTemperature = temperatureDefaultValues.MaxTemperature;

						this.initialminTemperature = this.minTemperature;
						this.initialmaxTemperature = this.maxTemperature;
					}
					const ExtremeTemperature = this.medicalDevicesList[i].assignedMedicalDevicesList.find(device => device.deviceType === 1);
					if (ExtremeTemperature) {
						const TemperatureExtremeValues = this.parseJson(ExtremeTemperature.deviceDefaultExtremeValuesJson);
						this.minTemperatureExtremeValue = TemperatureExtremeValues.MinTemperature;
						this.maxTemperatureExtremeValue = TemperatureExtremeValues.MaxTemperature;
					}
					patientsData.push(patientData);
				}
				this.patientDevices = patientDevices;
			});
		},
		async saveMonitoringPlan () {
			if (this.temperatureTimeChanges) {
				const device = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(
					(device) => device.deviceTypeTitle === 'Thermometer',
				);
				switch (this.selectedMeasurementPeriodThermometer) {
				case 'onePerDay':
					device.measurementsCount = 1;
					break;
				case 'twicePerDay':
					device.measurementsCount = 2;
					break;
				case 'threeTimesPerDay':
					device.measurementsCount = 3;
					break;
				case 'fourTimesPerDay':
					device.measurementsCount = 4;
				}
				let isFilled = true;
				for (let i = 1; i <= device.measurementsCount; i++) {
					const timeValue = this.device[`time${i}`];
					if (timeValue === null) {
						isFilled = false;
						break;
					}
				}
				if (!isFilled) {
					this.$store.commit('alerts/add', {
						type: 'error',
						message: this.$t('enter-times'),
					});
					return;
				}
				if (device.measurementsCount >= 1 && device.measurementsCount <= 4) {
					const timeProperties = ['time1', 'time2', 'time3', 'time4'];

					for (let i = 0; i < device.measurementsCount; i++) {
						this.device[timeProperties[i]] = this.device[timeProperties[i]] || device[timeProperties[i]];

						if (this.device[timeProperties[i]] === null) {
							return;
						}
					}
				}
				const body = {
					treatmentPlanId: device.treatmentPlanId,
					deviceType: device.deviceType,
					measurementPeriod: 'D',
					id: device.id,
					measurementsCount: device.measurementsCount,
					time1: this.device.time1 ? this.device.time1 : null,
					time2: this.device.time2 ? this.device.time2 : null,
					time3: this.device.time3 ? this.device.time3 : null,
					time4: this.device.time4 ? this.device.time4 : null,
				};
				await this.$store.dispatch('treatmentplan/updateTreatmentPlanDevice', body);
				await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
				this.closeDrawer();
			}
			if (this.oximeterTimeChanges) {
				const device = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(
					(device) => device.deviceTypeTitle === 'Oximeter',
				);
				switch (this.selectedMeasurementPeriodOximeter) {
				case 'onePerDay':
					device.measurementsCount = 1;
					break;
				case 'twicePerDay':
					device.measurementsCount = 2;
					break;
				case 'threeTimesPerDay':
					device.measurementsCount = 3;
					break;
				case 'fourTimesPerDay':
					device.measurementsCount = 4;
				}
				let isFilled = true;
				for (let i = 1; i <= device.measurementsCount; i++) {
					const timeValue = this.device[`time${i}`];
					if (timeValue === null) {
						isFilled = false;
						break;
					}
				}
				if (!isFilled) {
					this.$store.commit('alerts/add', {
						type: 'error',
						message: this.$t('enter-times'),
					});
					return;
				}
				if (device.measurementsCount >= 1 && device.measurementsCount <= 4) {
					const timeProperties = ['time1', 'time2', 'time3', 'time4'];

					for (let i = 0; i < device.measurementsCount; i++) {
						this.device[timeProperties[i]] = this.device[timeProperties[i]] || device[timeProperties[i]];

						if (this.device[timeProperties[i]] === null) {
							return;
						}
					}
				}
				const body = {
					treatmentPlanId: device.treatmentPlanId,
					deviceType: device.deviceType,
					measurementPeriod: 'D',
					id: device.id,
					measurementsCount: device.measurementsCount,
					time1: this.device.time1 ? this.device.time1 : null,
					time2: this.device.time2 ? this.device.time2 : null,
					time3: this.device.time3 ? this.device.time3 : null,
					time4: this.device.time4 ? this.device.time4 : null,
				};
				await this.$store.dispatch('treatmentplan/updateTreatmentPlanDevice', body);
				await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
				this.closeDrawer();
			}

			// CHOLESTEROL TIME CHANGES
			if (this.cholesterolTimeChanges) {
				const device = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(
					(device) => device.deviceTypeTitle === 'Cholesterol Meter',
				);
				switch (this.selectedMeasurementPeriodCholesterol) {
				case 'onePerDay':
					device.measurementsCount = 1;
					break;
				case 'twicePerDay':
					device.measurementsCount = 2;
					break;
				case 'threeTimesPerDay':
					device.measurementsCount = 3;
					break;
				case 'fourTimesPerDay':
					device.measurementsCount = 4;
				}
				let isFilled = true;
				for (let i = 1; i <= device.measurementsCount; i++) {
					const timeValue = this.device[`time${i}`];
					if (timeValue === null) {
						isFilled = false;
						break;
					}
				}
				if (!isFilled) {
					this.$store.commit('alerts/add', {
						type: 'error',
						message: this.$t('enter-times'),
					});
					return;
				}
				if (device.measurementsCount >= 1 && device.measurementsCount <= 4) {
					const timeProperties = ['time1', 'time2', 'time3', 'time4'];

					for (let i = 0; i < device.measurementsCount; i++) {
						this.device[timeProperties[i]] = this.device[timeProperties[i]] || device[timeProperties[i]];

						if (this.device[timeProperties[i]] === null) {
							return;
						}
					}
				}
				const body = {
					treatmentPlanId: device.treatmentPlanId,
					deviceType: device.deviceType,
					measurementPeriod: 'D',
					id: device.id,
					measurementsCount: device.measurementsCount,
					time1: this.device.time1 ? this.device.time1 : null,
					time2: this.device.time2 ? this.device.time2 : null,
					time3: this.device.time3 ? this.device.time3 : null,
					time4: this.device.time4 ? this.device.time4 : null,
				};
				await this.$store.dispatch('treatmentplan/updateTreatmentPlanDevice', body);
				await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
				this.closeDrawer();
			}
			if (this.weightscaleTimeChanges) {
				const device = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(
					(device) => device.deviceTypeTitle === 'Weight scale',
				);
				switch (this.selectedMeasurementPeriodWeight) {
				case 'onePerDay':
					device.measurementsCount = 1;
					break;
				case 'twicePerDay':
					device.measurementsCount = 2;
					break;
				case 'threeTimesPerDay':
					device.measurementsCount = 3;
					break;
				case 'fourTimesPerDay':
					device.measurementsCount = 4;
				}
				let isFilled = true;
				for (let i = 1; i <= device.measurementsCount; i++) {
					const timeValue = this.device[`time${i}`];
					if (timeValue === null) {
						isFilled = false;
						break;
					}
				}
				if (!isFilled) {
					this.$store.commit('alerts/add', {
						type: 'error',
						message: this.$t('enter-times'),
					});
					return;
				}
				if (device.measurementsCount >= 1 && device.measurementsCount <= 4) {
					const timeProperties = ['time1', 'time2', 'time3', 'time4'];

					for (let i = 0; i < device.measurementsCount; i++) {
						this.device[timeProperties[i]] = this.device[timeProperties[i]] || device[timeProperties[i]];

						if (this.device[timeProperties[i]] === null) {
							return;
						}
					}
				}
				const body = {
					treatmentPlanId: device.treatmentPlanId,
					deviceType: device.deviceType,
					measurementPeriod: 'D',
					id: device.id,
					measurementsCount: device.measurementsCount,
					time1: this.device.time1 ? this.device.time1 : null,
					time2: this.device.time2 ? this.device.time2 : null,
					time3: this.device.time3 ? this.device.time3 : null,
					time4: this.device.time4 ? this.device.time4 : null,
				};
				await this.$store.dispatch('treatmentplan/updateTreatmentPlanDevice', body);
				await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
				this.closeDrawer();
			}

			// Bloodpressure Device.
			if (this.bloodpressureTimeChanges) {
				const device = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(
					(device) => device.deviceTypeTitle === 'Blood pressure',
				);
				switch (this.selectedMeasurementPeriodBloodPressure) {
				case 'onePerDay':
					device.measurementsCount = 1;
					break;
				case 'twicePerDay':
					device.measurementsCount = 2;
					break;
				case 'threeTimesPerDay':
					device.measurementsCount = 3;
					break;
				case 'fourTimesPerDay':
					device.measurementsCount = 4;
				}
				let isFilled = true;
				for (let i = 1; i <= device.measurementsCount; i++) {
					const timeValue = this.device[`time${i}`];
					if (timeValue === null) {
						isFilled = false;
						break;
					}
				}
				if (!isFilled) {
					this.$store.commit('alerts/add', {
						type: 'error',
						message: this.$t('enter-times'),
					});
					return;
				}
				if (device.measurementsCount >= 1 && device.measurementsCount <= 4) {
					const timeProperties = ['time1', 'time2', 'time3', 'time4'];

					for (let i = 0; i < device.measurementsCount; i++) {
						this.device[timeProperties[i]] = this.device[timeProperties[i]] || device[timeProperties[i]];

						if (this.device[timeProperties[i]] === null) {
							return;
						}
					}
				}
				const body = {
					treatmentPlanId: device.treatmentPlanId,
					deviceType: device.deviceType,
					measurementPeriod: 'D',
					id: device.id,
					measurementsCount: device.measurementsCount,
					time1: this.device.time1 ? this.device.time1 : null,
					time2: this.device.time2 ? this.device.time2 : null,
					time3: this.device.time3 ? this.device.time3 : null,
					time4: this.device.time4 ? this.device.time4 : null,
				};
				await this.$store.dispatch('treatmentplan/updateTreatmentPlanDevice', body);
				await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
				this.closeDrawer();
			}

			if (this.bloodglucoseTimeChanges) {
				const device = this.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(
					(device) => device.deviceTypeTitle === 'Blood glucose',
				);
				switch (this.selectedMeasurementPeriodGlucoseMeter) {
				case 'onePerDay':
					device.measurementsCount = 1;
					break;
				case 'twicePerDay':
					device.measurementsCount = 2;
					break;
				case 'threeTimesPerDay':
					device.measurementsCount = 3;
					break;
				case 'fourTimesPerDay':
					device.measurementsCount = 4;
				}
				let isFilled = true;
				for (let i = 1; i <= device.measurementsCount; i++) {
					const timeValue = this.device[`time${i}`];
					if (timeValue === null) {
						isFilled = false;
						break;
					}
				}
				if (!isFilled) {
					this.$store.commit('alerts/add', {
						type: 'error',
						message: this.$t('enter-times'),
					});
					return;
				}
				if (device.measurementsCount >= 1 && device.measurementsCount <= 4) {
					const timeProperties = ['time1', 'time2', 'time3', 'time4'];

					for (let i = 0; i < device.measurementsCount; i++) {
						this.device[timeProperties[i]] = this.device[timeProperties[i]] || device[timeProperties[i]];

						if (this.device[timeProperties[i]] === null) {
							return;
						}
					}
				}
				const body = {
					treatmentPlanId: device.treatmentPlanId,
					deviceType: device.deviceType,
					measurementPeriod: 'D',
					id: device.id,
					measurementsCount: device.measurementsCount,
					time1: this.device.time1 ? this.device.time1 : null,
					time2: this.device.time2 ? this.device.time2 : null,
					time3: this.device.time3 ? this.device.time3 : null,
					time4: this.device.time4 ? this.device.time4 : null,
				};
				await this.$store.dispatch('treatmentplan/updateTreatmentPlanDevice', body);
				await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
				this.closeDrawer();
			}

			async function handleDeviceChanges (deviceType, body, context) {
				const deviceIdentifier = context.patientDevices.data.medicalDevicesList[0].assignedMedicalDevicesList
					.find(device => device.deviceType === deviceType)?.deviceIdentifier;

				if (!deviceIdentifier) return;
				await context.$store.dispatch('medicaldevice/updateDeviceDefaultValuesJsonForMedicalDevice', {
					...body,
					deviceIdentifier,
				});

				context.$emit('created');
				context.closeDrawer();
			}
			async function updateDeviceValues (deviceType, values, patientId, context) {
				const body = {
					deviceDefaultValuesJson: JSON.stringify(values),
					patientId: patientId,
				};
				await handleDeviceChanges(deviceType, body, context);
				await context.loadDevicesForPatient();
			}
			if (this.cholesterolChanges) {
				const values = {
					BloodSugarLevelMax: this.formatNumber(this.bloodSugarLevelMax),
					BloodSugarLevelMin: this.formatNumber(this.bloodSugarLevelMin),
					CholesterolLevelMin: this.formatNumber(this.cholesterolLevelMin),
					CholesterolLevelMax: this.formatNumber(this.cholesterolLevelMax),
				};
				await updateDeviceValues(7, values, this.detailedUser.userData.id, this);
			}

			if (this.bloodpressureChanges) {
				const values = {
					SystolicMin: this.formatNumber(this.systolicMin),
					SystolicMax: this.formatNumber(this.systolicMax),
					DiastolicMin: this.formatNumber(this.diastolicMin),
					DiastolicMax: this.formatNumber(this.diastolicMax),
					HeartBeatMax: this.formatNumber(this.heartbeatMax),
					HeartBeatMin: this.formatNumber(this.heartbeatMin),
				};
				await updateDeviceValues(3, values, this.detailedUser.userData.id, this);
			}

			if (this.temperatureChanges) {
				const values = {
					MinTemperature: this.formatNumber(this.minTemperature),
					MaxTemperature: this.formatNumber(this.maxTemperature),
				};
				await updateDeviceValues(1, values, this.detailedUser.userData.id, this);
			}

			if (this.oximeterChanges) {
				const values = {
					SpO2: this.formatNumber(this.sp02),
					HeartBeatMin: this.formatNumber(this.HeartBeatMinOximeter),
					HeartBeatMax: this.formatNumber(this.HeartBeatMaxOximeter),
					PiMin: this.formatNumber(this.piMin),
					PiMax: this.formatNumber(this.piMax),
				};
				await updateDeviceValues(2, values, this.detailedUser.userData.id, this);
			}

			if (this.bloodglucoseChanges) {
				const values = {
					BloodSugarLevelMin: this.formatNumber(this.bloodSugarLevelMinGlucose),
					BloodSugarLevelMax: this.formatNumber(this.bloodSugarLevelMaxGlucose),
				};
				await updateDeviceValues(6, values, this.detailedUser.userData.id, this);
			}

			if (this.changedTitleDescription) {
				const body = {
					treatmentPlanId: this.patientActiveTreatmentPlan.id,
					tpName: this.patientActiveTreatmentPlan.tpName,
					tpDescription: this.patientActiveTreatmentPlan.tpDescription,
				};
				const res = await this.$store.dispatch('treatmentplan/updateTreatmentPlan', body);
				if (!res.message && res.errors === undefined) {
					this.$emit('created');
				}
				await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);

				this.closeDrawer();
			}
			const successMessage = this.$t('monitoring-plan-updated');
			this.$store.commit('alerts/add', { type: 'success', message: successMessage });
			this.$store.commit('alerts/clear', { type: 'success', message: successMessage });

			this.$emit('created');

			this.changesMade = false;
			this.oximeterChanges = false;
			this.oximeterTimeChanges = false;
			this.cholesterolTimeChanges = false;
			this.cholesterolChanges = false;
			this.temperatureTimeChanges = false;
			this.temperatureChanges = false;
			this.bloodpressureTimeChanges = false;
			this.bloodpressureChanges = false;
			this.bloodglucoseChanges = false;
			this.bloodglucoseTimeChanges = false;
			this.isEnabled = false;

			// this.updateSurveyTemplate();
		},

		// FD added these
		async openWellnessCard () {
			this.openWellness = true;
			await this.getSurveyRecurrencePeriod();
		},
		async getSurveyRecurrencePeriod () {
			await this.$store.dispatch('verifiers/getSurveyRecurrencePeriod');
		},
		async getMentalHealthSurveyTemplates () {
			const body = {
				hospitalId: this.authUserData.hospitalAssignedData.id,
			};
			await this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', body.hospitalId);
		},
		async assignSurveyTemplatetoPatient () {
			let startDate = null;
			let endDate = null;

			if (Array.isArray(this.date)) {
				startDate = this.date[0];
				endDate = this.date[1] ? this.date[1] : null;
			} else {
				startDate = this.date;
			}

			const body = {
				patientUserId: this.detailedUser.userData.id,
				surveyTemplateId: this.selectedTemplate,
				StartDate: startDate,
				EndDate: endDate,
				recurrenceRuleForSurvey: this.selectedPeriod,
				hospitalId: this.authUserData.hospitalAssignedData.id,
			};
			await this.$store.dispatch('mentalhealth/assignSurveyTemplatetoPatient', body);
			// if (response.resFlag) {
			//   showSuccessAlert('Survey Added!');
			// }
			// } catch (error) {
			//   showErrorAlert('Survey not added!');
			// }

			// this.CloseDrawer();
			this.closeDrawer();
		},
	},
};
</script>
