<template>
	<v-card class=" mx-auto" elevation="0">
    <v-card-title class="text-left card-header fixed-card-title2">
      <v-row>
        <v-col cols="10">
          <v-toolbar-title>{{ $t("edit-note") }}</v-toolbar-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-icon class="white--text float-end mr-5" @click="closeDrawer">
          mdi-close
        </v-icon>
      </v-row>
    </v-card-title>

		<!-- Middle Content -->
    <v-card-text  class="px-0">
      <v-textarea class="mt-2 px-2" :height="pageHeight(180)" solo no-resize v-model="noteById.noteDescription"></v-textarea>
    </v-card-text>
		<v-card-actions class="fixed-card-actions">
		<!-- Bottom Buttons -->
		<v-row class="mb-0">
			<v-col cols="6">
			<v-btn
				@click="closeDrawer"
				elevation="0"
				class="capitalize-text cancel-button-style"
			>
				{{ $t("cancel") }}
			</v-btn>
			</v-col>
			<v-col cols="6">
			<v-btn
				@click="editNote"
				class="save-button-style"
			>
				{{ $t("save") }}
			</v-btn>
			</v-col>
		</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>

import { closeBlack } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
// import patientmetadata from '@/data/store/modules/patientmetadata';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
  data () {
    return {
      closeBlack: closeBlack,
      medicalNote: '',
      newNote: '',
      editedNote: '',
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
		noteById: (state) => state.appointments.noteById,
		detailedUser: (state) => state.users.detailedUser,
    }),
    ...mapGetters({
		getNotes: 'appointments/getNotes',
		getUserById: 'users/getUserById',
		roleName: 'authentication/getRole',
    pageHeight: 'pageHeight',
    }),
    rowsHeight () {
      if (this.$vuetify.breakpoint.width <= 1280 && this.$vuetify.breakpoint.height <= 800) {
        return '23';
      }
      if (this.$vuetify.breakpoint.width >= 1280 && this.$vuetify.breakpoint.height <= 800) {
        return '23';
      }
      if (this.$vuetify.breakpoint.xl) {
        return '30';
      }
      return '30';
    },
  },

  mounted () {
  },

  methods: {
    async closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
      // const data = { patientId: this?.getUserById.id };
      // await this.$store.dispatch('appointments/getNotesWithPatient', this.$route.params.id);
      // await this.getNote();
    },
    async editNote () {
      this.newNote = this.noteById.noteDescription;
      const body = {
        appointmentNoteId: this.noteById.noteId,
        noteDescription: this.newNote,
      };
      if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
        try {
          await this.$store.dispatch('appointments/editNotesForPatient', body);
			showSuccessAlert(this.$t('noteEditSuccess'));
			this.closeDrawer();
        } catch (error) {
			showErrorAlert(this.$t('noteEditFail'));
        }
       await this.$store.dispatch('appointments/getNotesWithPatient', this.$route.params.id);
      }
    },

  },

};
</script>
