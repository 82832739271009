<template>
  <v-card
      elevation="0"
      v-if="surveyData"
  >
    <v-card-title class="card-header" id="fixed-card-title" >
      <v-row dense>
        <v-col cols="6" class="d-flex align-center justify-start">
          <v-toolbar-title class="my-2">{{$t('edit-assessment')}}</v-toolbar-title>
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-end">
          <v-switch
              class="my-n1"
              color="light-green"
              hide-details
              dense
              @change="disableSurvey"
              v-model="surveyData.status"
              icon-size="10"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-title>
    <!-- Role -->
    <v-card-text class="mb-16">
      <v-form
          ref="form"
          v-model="valid"
      >
        <!--Assessment Name-->
        <v-text-field
            class="my-3"
            outlined
            v-model="surveyData.templateName"
            dense
            hide-details
            :label="$t('assessment-name')"
            required
        ></v-text-field>
        <!--Description-->
        <v-textarea
            class="no-resize my-3"
            outlined
            multi-line
            :rows="5"
            dense
            v-model="surveyData.templateDescription"
            hide-details
            :label="$t('description')"
            required
        ></v-textarea>
        <!--Questions Section-->
        <v-row dense class="mt-5 mx-0">
          <span class="font-size-small-bold-text">{{ $t('questions') }}</span>
        </v-row>
        <!--Add Question Button-->
        <v-row dense class="my-3 d-flex justify-center align-center">
          <v-btn
              elevation="0"
              outlined
              width="100%"
              @click="createQuestion()"
          >
            <v-icon dense color="main_black" class="mdi mdi-plus"></v-icon>
            {{$t('question')}}
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-if="showSubmitButton"
                class="my-1 mx-1"
                outlined
                v-model="newQuestion"
                dense
                hide-details
                :label="$t('question')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!--        VTORO-->
        <div v-if="showSubmitButton">
          <v-row dense class="mx-1 pb-3" >
            <v-autocomplete
                v-model="selectedVerifier"
                :items="getSurveyTypesForForm"
                :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                item-value="lowValue"
                hide-details
                outlined
                dense
                :label="$t('type')"
            ></v-autocomplete>
          </v-row>
          <div class="align-center d-flex justify-start" v-if="showSubmitButton">
            <v-simple-checkbox
                color="primary"
                dense
                v-model="isMandatory"
            >
            </v-simple-checkbox>
            <div v-if="showSubmitButton">
              <span class="font-weight-bold color-black">{{$t('is-mandatory')}}</span>
            </div>
          </div>
        </div>
        <v-btn
            v-if="showSubmitButton"
            elevation="0"
            outlined
            @click="submitQuestion"
            class="cancel-button-style mt-1 mb-4"
        >
          {{$t('create-question')}}
        </v-btn>
        <v-card class="overflow-auto mt-10"  elevation="0" :height="testHeight">
        <v-expansion-panels class="d-block">
          <draggable :list="surveyById.questionsListWithAnswers" @end="(event) => endQuestionsDrag(event, surveyById.questionsListWithAnswers)">
            <v-expansion-panel class="my-0" v-for="(question, index) in surveyById.questionsListWithAnswers" :key="index">
              <v-expansion-panel-header class="custom-header py-0">
                  <div class="d-flex align-center">
                    <v-avatar size="22px" color="chips">
                      <span class="text-white">{{index+1}}</span>
                    </v-avatar>
                    <span class="font-size-small-bold-text ml-2">{{ question.question }}</span>
                    <v-icon color="main_black" class="mr-2 ml-auto" @click="deleteQuestion(question, index)">mdi-trash-can-outline</v-icon>
                  </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content >
                <!--Question-->
                <v-text-field
                    class="my-3"
                    outlined
                    dense
                    clear-icon="mdi-backspace-outline"
                    clearable
                    @click:clear="deleteOption(question)"
                    hide-details
                    v-model="question.question"
                    :label="$t('question')"
                    required
                ></v-text-field>
                <!--                    TRETO-->
                <v-row dense class="mx-0 pb-3">
                  <v-autocomplete
                      v-model="question.answerTypeId"
                      :items="getSurveyTypesForForm"
                      :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                      item-value="lowValue"
                      hide-details
                      outlined
                      style="z-index:100000"
                      dense
                      @change="updateQuestion(question)"
                      :label="$t('type')"
                  ></v-autocomplete>
                </v-row>
                <div v-if="question.answerTypeTitle !== 'Custom text' && question.answerTypeTitle !== null && question.answerTypeTitle !== undefined">
                  <v-row><span class="mt-4 ml-3 mb-4 font-size-small-bold-text" v-if="question.answerTypeId!==3">{{$t('answers')}}</span></v-row>
                  <draggable :list="question.offeredAnswersList" tag="tbody" @end="(event) => endDrag(event, question.offeredAnswersList)">
                    <v-row dense class="mt-n3 mb-0" v-for="(answer, answerIndex) in question.offeredAnswersList" :key="answerIndex">
                      <v-col cols="12" v-if="question.answerTypeId!==3">
                        <v-text-field
                            class="my-2"
                            outlined
                            dense
                            hide-details
                            v-model="answer.possibleAnswer"
                        >
                          <template v-slot:append>
                            <div class="margin-left70">
                              <v-icon
                                  color="main_black"
                                  @click="editAnswer(question, answer, answerIndex)"
                              >
                                mdi-content-save
                              </v-icon>
                              <v-icon
                                  color="main_black"
                                  @click="deleteItem(answer, index)"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="8" class="align-left text-left d-flex my-1" v-if="question.answerTypeId!==3">
                        <v-simple-checkbox
                            color="primary"
                            dense
                            v-model="answer.triggerNotification"
                            @click="editAnswer(question, answer, answerIndex)"
                        ></v-simple-checkbox>
                        <span class="font-weight-bold mt-1">{{$t('trigger-notification')}}</span>
                      </v-col>
                      <v-col cols="4" class="d-flex align-center" v-if="surveyById.mentalHealthCategoryId===27">
                        <i class="mr-8">Points:</i>
                        <v-text-field v-model="answer.scorePoints" :value="answer.scorePoints" hide-details dense @blur="editAnswerRow(answer)" outlined></v-text-field>
                      </v-col>
                    </v-row>
                  </draggable>
                </div>
                <v-text-field
                    class="my-3"
                    outlined
                    v-if="!showFirstButton"
                    v-model="newAnswer"
                    dense
                    hide-details
                    :label="$t('answer')"
                ></v-text-field>
                <v-row dense class="mx-0 mt-3" >
                  <v-col v-if="!showFirstButton">
                    <v-btn
                        elevation="0"
                        outlined
                        width="100%"
                        class="text-capitalize"
                        @click="createAnswer(index, question)"
                    >
                      <v-icon color="black">mdi-plus</v-icon>
                      <span class="mx-2 font-size-small-bold-text">{{$t('add-answer')}}</span>
                    </v-btn>
                  </v-col>
                  <v-col v-if="showFirstButton && question.answerTypeId!==3">
                    <v-btn
                        elevation="0"
                        outlined
                        width="100%"
                        class="text-capitalize"
                        @click="addAnswer()"
                    >
                      <v-icon color="black">mdi-plus</v-icon>
                      <span class="mx-2 text-none font-size15">{{ $t('add-new-answer') }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
        </v-card>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              class="save-button-style"
              @click="updateSurveyTemplate"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
export default {
	name: 'DashboardCoreAppBar',
	components: {
		draggable,
	},
	data: () => ({
		assessmentName: '',
    selectedCustom: null,
    currentPanel: null,
    surveyId: null,
		isMandatory: false,
    showQuestions: true,
		selected: [],
		checkbox: false,
		showFirstButton: true,
		selectedVerifier: -1,
		showSubmitButton: false,
		newQuestion: '',
		newAnswer: '',
		valid: true,
		notifyAnswer: false,
		questions: [
			{
				name: '',
				type: 3,
				options: [],
			},
		],
		questionsList: [],
		description: '',
		questionOptions: [],
		selectedQuestionType: '',
		dragOptions: {
			handle: '.drag-btn',
		},
	}),
	computed: {
		...mapState({
			surveyById: (state) => state.mentalhealth.survey,
			userData: (state) => state.authentication.userData,
		}),
		...mapGetters({
			// hospitalId: 'authentication/getHospitalId',
			getMentalHealthCategoryForForm: 'verifiers/getMentalHealthCategoryForForm',
			getSurveyTypesForForm: 'verifiers/getSurveyTypesForForm',
		}),
    testHeight () {
      if (this.$vuetify.breakpoint.width <= 1280 && this.$vuetify.breakpoint.height <= 800) {
        return '290';
      }
      return '700';
    },
		surveyData () {
			// return this.surveyById ? this.surveyById : null;
      return this.surveyById ? this.surveyById : {};
		},
		hospitalId () {
			const hospitalId = this.userData.hospitalAssignedData.id;
			return hospitalId;
		},
	},
  methods: {
    handlePanelClick (index) {
      this.currentPanel = this.currentPanel === index ? null : index;
    },
    editAnswerRow (item) {
      const object = {
        stQuestionId: item.stQuestionId,
        possibleAnswer: item.possibleAnswer,
        orderNumber: item.orderNumber,
        id: item.id,
        triggerNotification: item.triggerNotification,
        scorePoints: item.scorePoints === '' ? null : item.scorePoints,
      };
      // Dispatch the update API call
      this.$store.dispatch('mentalhealth/updateAnswer', object);
    },
    endDrag (event, items) {
      const itemId = items[event.newDraggableIndex].id;
      const orderId = event.newDraggableIndex + 1;

      // Update the order of items in the items array
      items.forEach((item, index) => {
        item.orderNumber = index + 1;
      });

      const updatedAnswer = {
        stOfferedAnswerId: itemId,
        changedOrderNumberValue: orderId,
      };

      // Call the API only once for the moved item
      this.$store.dispatch('mentalhealth/stofferAnswerReorder', updatedAnswer);
    },
    endQuestionsDrag (event, items) {
      const questionId = items[event.newDraggableIndex].id;
      const orderId = event.newDraggableIndex + 1;

      const updatedQuestion = {
        StQuestionId: questionId,
        ChangedOrderNumberValue: orderId,
      };
      this.$store.dispatch('mentalhealth/stquestionReorder', updatedQuestion);
    },
		updateTriggerNotification (question, answerIndex) {
			const triggerNotificationValue = this.question.offeredAnswersList[answerIndex].triggerNotification;
			this.$set(question.offeredAnswersList[answerIndex], 'triggerNotification', !triggerNotificationValue);
		},
		createQuestion () {
			this.showSubmitButton = true;
			this.questions.push({
				type: 3,
				name: '',
				options: [],
				id: null,
			});
		},
		addAnswer () {
			this.showFirstButton = false;
      this.showQuestions = false;
			this.questions.push({
				type: 3,
				name: '',
				options: [],
				id: null,
			});
		},
		createAnswer (index, question) {
			var createAnswer = {
				stQuestionId: question.id, // Use the id of the current question
				possibleAnswer: this.newAnswer,
				orderNumber: question.offeredAnswersList.length + 1,
				triggerNotification: this.notifyAnswer,
			};
			this.$store.dispatch('mentalhealth/createAnswer', createAnswer).then((res) => {
				if (res.data.triggerNotification) {
					this.selected.push(
						res.data.id,
					);
				}
				this.showFirstButton = true;
				this.newAnswer = '';
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyData.id);
			});
		},
		submitQuestion () {
			var object = {
				surveyTemplateId: this.surveyData.id,
				question: this.newQuestion,
				answerTypeId: this.selectedVerifier,
				orderNumber: this.questions.length,
				isMandatory: this.isMandatory,
			};

			this.$store.dispatch('mentalhealth/createQuestion', object).then((res) => {
				this.questions.push({
					type: res.answerTypeId,
					name: res.question,
					options: [],
					id: res.id,
				});
				this.showSubmitButton = false;
				this.newQuestion = '';
				this.selectedVerifier = -1;
				this.isMandatory = false;
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyData.id);
				this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
			});
		},

		updateQuestion (question) {
			const updatedQuestion = {
				question: question.question,
				answerTypeId: question.answerTypeId,
				orderNumber: question.orderNumber,
				isMandatory: question.isMandatory,
				id: question.id,
			};
      this.selectedCustom = updatedQuestion.answerTypeId;
			this.$store.dispatch('mentalhealth/updateQuestion', updatedQuestion);
		},
    // updateQuestion (question, index) {
    //   if (question.id !== null && question.id !== undefined) {
    //     const object = {
    //       question: question.name,
    //       answerTypeId: question.type,
    //       orderNumber: index + 1,
    //       isMandatory: true,
    //       id: question.id,
    //     };
    //     this.$store.dispatch('mentalhealth/updateQuestion', object);
    //   }
    // },
		editAnswer (question, answer) {
			const index = question.offeredAnswersList.indexOf(answer);
			if (index === -1) {
				return;
			}
			const updatedAnswer = {
				stQuestionId: question.id,
				possibleAnswer: answer.possibleAnswer,
				orderNumber: index + 1,
				triggerNotification: answer.triggerNotification, // Use the triggerNotification property of the answer
				id: answer.id,
        scorePoints: answer.scorePoints === '' ? null : answer.scorePoints,
			};
			this.$store.dispatch('mentalhealth/updateAnswer', updatedAnswer).then(() => {
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyData.id);
			});
		},
    disableSurvey () {
      const object = {
        templateName: this.assessmentName,
        mentalHealthCategoryId: this.mentalHealthCategoryId,
        templateDescription: this.description,
        id: this.surveyData.id,
        status: this.surveyData.status ? 1 : 0,
      };
       this.$store.dispatch('mentalhealth/updateSurveyTemplate', object).then(res => {
        // Handle success or any other actions you need to take
        this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
        this.surveyId = res.id;
      });
    },
		async updateSurveyTemplate () {
			this.assessmentName = this.surveyData.templateName;
			this.description = this.surveyData.templateDescription;

			const selectedMentalHealthCategory = this.getMentalHealthCategoryForForm.find(category =>
				category.longDescription === this.surveyData.mentalHealthCategoryTitle,
			);
			this.mentalHealthCategoryId = selectedMentalHealthCategory ? selectedMentalHealthCategory.lowValue : null;
			const object = {
				templateName: this.assessmentName,
				mentalHealthCategoryId: this.mentalHealthCategoryId,
				templateDescription: this.description,
				id: this.surveyData.id,
        status: this.surveyData.status ? 1 : 0,
			};

			await this.$store.dispatch('mentalhealth/updateSurveyTemplate', object).then(res => {
				// Handle success or any other actions you need to take
				this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
				this.surveyId = res.id;
			});
			this.closeDrawer();
		},
		deleteOption (questionOption) {
			this.questionOptions = this.questionOptions.filter(
				(option) => option !== questionOption,
			);
		},
		deleteQuestion (item) {
			this.$store.dispatch('mentalhealth/deleteQuestion', item.id).then(() => {
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyData.id);
			});
		},
    deleteItem (item, index) {
      this.$store.dispatch('mentalhealth/deleteAnswer', item.id).then(res => {
        this.$store.dispatch('mentalhealth/getSurveysById', this.surveyById.id);
      });
    },
		closeDrawer () {
      // clean up the current select survey template from the store
      this.$store.commit('mentalhealth/CLEAR_CURRENT_SURVEY_TEMPLATE', null);
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},
	},
	watch: {
		checkbox (val) {
			this.notifyAnswer = val;
		},
	},

};
</script>
