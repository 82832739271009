<template>
    <v-card
        class="mx-auto"
        elevation="0"
        v-if="selectedMedicalInstitution"
    >
        <v-card-title class="card-header text-left fixed-card-title2">
            <v-row>
                <v-col cols="10">
                    <v-toolbar-title >{{$t('edit')}} {{$t('medicalInstitution')}}</v-toolbar-title>
                </v-col>
              <v-spacer></v-spacer>
              <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <!-- Data -->
                <v-container>
                    <v-row class="mt-1">
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.institutionName"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('organization-name')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-textarea
                                outlined
                                hide-details
                                dense
                                v-model="selectedMedicalInstitution.description"
                                :label="$t('description')"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <div class="pt-4">
                                <h5 class="heading text-left">{{this.$t('address')}}</h5>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.address"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('address')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.city"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('city')"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.state"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('state')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.zip"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('zip')"
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.country"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('country')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <div class="pt-4">
                                <h5 class="heading text-left">{{this.$t('contact')}}</h5>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.contactPerson"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-person')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.contactPhone"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-phone')"
                                @input="formatPhoneNumber"
                                required>
                            </v-text-field>
                            <v-text-field
                                class="ml-2"
                                outlined
                                hide-details="auto"
                                dense
                                v-model="selectedMedicalInstitution.contactEmail"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-email')"
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="fixed-card-actions">
            <v-row class="mb-0">
                <v-col cols="6">
                    <v-btn
                        @click="closeDrawer"
                        elevation="0"
                        class="cancel-button-style capitalize-text"
                        >
                    {{$t('cancel')}}
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn
                        :disabled="!valid"
                        @click="saveMedicalInstitution"
                        class="save-button-style"
                    >
                    {{ $t('save') }}
                    </v-btn>
                </v-col>
            </v-row>
      </v-card-actions>
    </v-card>
</template>

<script>
    import { closeWhite } from '@/assets/index';
    import { mapGetters, mapState } from 'vuex';
    import { AsYouType } from 'libphonenumber-js';
    import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

    export default {
        name: 'MedicalInstitutionEditDrawerDialog',
        components: {},
        data: () => ({
            closeWhite: closeWhite,
            valid: true,
            menu: false,
        }),
        mounted () {

        },
      beforeDestroy () {
          this.selectedMedicalInstitution = [];
      },
      // created () {
        //     this.$store.watch(state => state.medicalInstitutions.selectedMedicalInstitution, (newValue, oldValue) => {
        //         if (newValue == null) {
        //             this.selectedMedicalInstitution = oldValue;
        //         } else {
        //             this.selectedMedicalInstitution = newValue;
        //         }
        //     });
        // },
        computed: {
            ...mapGetters({
                // getSelectedVerifier: 'verifiers/getSelectedVerifier',
                delay: 'authentication/getDelay',
            }),
          ...mapState({
            selectedMedicalInstitution: (state) => state.medicalInstitutions.selectedMedicalInstitution,
          }),
        },
        methods: {
          formatPhoneNumber (val) {
            this.selectedMedicalInstitution.contactPhone = new AsYouType().input(val);
          },
            getAllMedicalInstitutions () {
                this.$store.dispatch('medicalInstitutions/getMedicalInstitutions').then(() => {
                    this.closeDrawer();
                });
            },
            async saveMedicalInstitution () {
                const waitAddressValidation = await this.checkAndFixLocationDetails();
                if (!waitAddressValidation) {
                    // this.validationErrors.push('address');
                    // go on with the given addres data
                }

                this.selectedMedicalInstitution.contactPhone = new AsYouType().input(this.selectedMedicalInstitution.contactPhone);

                if (this.$refs.form.validate()) {
                    const body = {
                        id: this.selectedMedicalInstitution.id,
                        institutionName: this.selectedMedicalInstitution?.institutionName,
                        description: this.selectedMedicalInstitution.description,
                        contactPerson: this.selectedMedicalInstitution.contactPerson,
                        contactPhone: this.selectedMedicalInstitution.contactPhone,
                        address: this.selectedMedicalInstitution.address,
                        zip: this.selectedMedicalInstitution.zip,
                        city: this.selectedMedicalInstitution.city,
                        state: this.selectedMedicalInstitution.state,
                        country: this.selectedMedicalInstitution.country,
                        addressLatitude: this.selectedMedicalInstitution.addressLatitude,
                        addressLongitude: this.selectedMedicalInstitution.addressLongitude,
                        contactEmail: this.selectedMedicalInstitution.contactEmail,
                        mediaServerData: this.selectedMedicalInstitution.mediaServerData,
                    };

                    await this.$store.dispatch('medicalInstitutions/editMedicalInstitution', body)
                    .then((res) => {
                        if (res) {
                            showSuccessAlert(this.$t('success-operation'));
                            this.getAllMedicalInstitutions(); // reload the list of organizations
                        } else {
                            showErrorAlert(this.$t('failed-operation'));
                        }
                    });
                } else {
                    this.$refs.form.validate();
                }
            },
            save (date) {
                this.$refs.menu.save(date);
            },
            closeDrawer () {
                this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
                this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
            },
            async checkAndFixLocationDetails () {
                try {
                    if (this.selectedMedicalInstitution.address !== '' && this.selectedMedicalInstitution.city !== '' && this.selectedMedicalInstitution.zip !== '' && this.selectedMedicalInstitution.state !== '' && this.selectedMedicalInstitution.country !== '') {
                        var addressForValidation;
                        addressForValidation = this.selectedMedicalInstitution.address + ', ' + this.selectedMedicalInstitution.city + ', ' + this.selectedMedicalInstitution.state + ' ' + this.selectedMedicalInstitution.zip + ', ' + this.selectedMedicalInstitution.country;
                        const geoLocation = await this.$store.dispatch(
                            'googleAPI/getGeoLocation',
                            addressForValidation,
                        );
                        if (!geoLocation) {
                            // this.selectedMedicalInstitution.addressLatitude = 0.01;
                            // this.selectedMedicalInstitution.addressLongitude = 0.01;
                            return false;
                        }
                        if (geoLocation.address === undefined || geoLocation.address == null) {
                            // this.selectedMedicalInstitution.addressLatitude = 0.01;
                            // this.selectedMedicalInstitution.addressLongitude = 0.01;
                            return false;
                        }

                        const addressComponents = geoLocation.address;

                        this.selectedMedicalInstitution.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
                        this.selectedMedicalInstitution.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
                        this.selectedMedicalInstitution.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.selectedMedicalInstitution.address : geoLocation.display_name;
                        this.selectedMedicalInstitution.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
                        this.selectedMedicalInstitution.state = addressComponents.state || 'N/A';
                        this.selectedMedicalInstitution.country = addressComponents.country || 'N/A';
                        this.selectedMedicalInstitution.zip = addressComponents.postcode || 'N/A';
                        return true;
                    } else {
                        this.selectedMedicalInstitution.addressLatitude = 0.01;
                        this.selectedMedicalInstitution.addressLongitude = 0.01;
                        return false;
                    }
                } catch (error) {
                    return false;
                }
            },
        },
    };
</script>
