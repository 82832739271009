<template>
	<div>
	<v-navigation-drawer
		v-model="updateDrawer"
		right
		app
    stateless
		temporary
		active-class="background-color: 'red'"
		:width="480"
    style="z-index:2000"
	>
    <profile-settings v-if="page2 === 'settings'"></profile-settings>
		<staff-edit v-if="page2 === 'medicalstaff'"></staff-edit>
		<medical-team-edit v-if="page2 === 'medicalteam'"></medical-team-edit>
		<wellness-assessments-edit v-if="page2 === 'wellnessassessments'"></wellness-assessments-edit>
		<patient-edit v-if="page2 === 'patientdetails' && activePatientDetailsDrawer == 'patientEdit'" @close="clearDrawer()"></patient-edit>
		<DiagnosisEdit v-if="page2 === 'patientdetails' && activePatientDetailsDrawer == 'diagnosisEdit'" ></DiagnosisEdit>
		<AllergiesEdit v-if="page2 === 'patientdetails' && activePatientDetailsDrawer == 'allergiesEdit'" ></AllergiesEdit>
		<TreatmentEdit v-if="page2 === 'patientdetails' && activePatientDetailsDrawer == 'treatmentEdit'" ></TreatmentEdit>
		<AddNoteDrawer v-if="page2 === 'patientdetails' && activePatientDetailsDrawer == 'addNote'" ></AddNoteDrawer>
		<EditNoteDrawer v-if="page2 === 'patientdetails' && activePatientDetailsDrawer == 'editNote'" ></EditNoteDrawer>
<!--		<EditNoteDrawer v-if="$route.path.match(/\/patientdetails\/\d+/ && activePatientDetailsDrawer == 'editNote'" ></EditNoteDrawer>-->
		<monitoring-plans-edit v-if="page2 === 'patientdetails' && activePatientDetailsDrawer == 'monitoringPlansEdit'"></monitoring-plans-edit>
<!--		<notification-drawer v-if="$route.path === '/dashboard'"></notification-drawer>-->
		<verifier-edit v-if="page2 === 'verifier'"></verifier-edit>
		<medical-institution-edit v-if="page2 === 'medicalinstitution'"></medical-institution-edit>
		<hospital-edit v-if="page2 === 'offices'"></hospital-edit>
		<hospital-admin-edit v-if="page2 === 'admins'"></hospital-admin-edit>
		<edit-monitoring-drawer v-if="page2 === 'monitoring'"></edit-monitoring-drawer>
	</v-navigation-drawer>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StaffEdit from '@/views/DrawerDialogs/StaffEdit.vue';
import MedicalTeamEdit from '@/views/DrawerDialogs/MedicalTeamEdit.vue';
import PatientEdit from '@/views/DrawerDialogs/PatientEdit.vue';
import WellnessAssessmentsEdit from '@/views/DrawerDialogs/WellnessAssessmentsEdit.vue';
import MonitoringPlansEdit from '@/views/DrawerDialogs/MonitoringPlansEdit.vue';
import DiagnosisEdit from '@/views/DrawerDialogs/DiagnosisEdit.vue';
import AllergiesEdit from '@/views/DrawerDialogs/AllergiesEdit.vue';
import TreatmentEdit from '@/views/DrawerDialogs/TreatmentEdit.vue';
import AddNoteDrawer from '@/views/DrawerDialogs/AddNoteDrawer.vue';
// import NotificationDrawer from '@/views/DrawerDialogs/NotificationDrawer.vue';
import VerifierEdit from '@/views/DrawerDialogs/VerifierEdit.vue';
import MedicalInstitutionEdit from '@/views/DrawerDialogs/MedicalInstitutionEdit.vue';
import HospitalEdit from '@/views/DrawerDialogs/HospitalEdit.vue';
import HospitalAdminEdit from '@/views/DrawerDialogs/HospitalAdminEdit.vue';
import EditNoteDrawer from '@/views/DrawerDialogs/EditNoteDrawer.vue';
import EditMonitoringDrawer from '@/views/DrawerDialogs/EditMonitoringDrawer';
import ProfileSettings from '@/views/DrawerDialogs/ProfileSettings.vue';

export default {
	components: {
    ProfileSettings,
		WellnessAssessmentsEdit,
		PatientEdit,
		StaffEdit,
		MedicalTeamEdit,
		MonitoringPlansEdit,
    EditMonitoringDrawer,
		DiagnosisEdit,
		AllergiesEdit,
		TreatmentEdit,
		AddNoteDrawer,
		// NotificationDrawer,
		VerifierEdit,
		MedicalInstitutionEdit,
		HospitalEdit,
		HospitalAdminEdit,
    EditNoteDrawer,
	},
	data () {
		return {
			activePatientDetailsDrawer: '',
			items: [
				{ title: 'Home', icon: 'mdi-view-dashboard' },
				{ title: 'About', icon: 'mdi-forum' },
			],

		};
	},
	computed: {
		...mapState(['barColor', 'barImage', 'webrtcErrors', 'pageSize']),
		...mapGetters({
			pageWidth: 'pageHeight',
      page2: 'getPage2',
		}),
		updateDrawer: {
			get () {
				this.selectPatientDrawer(this.$store?.state.activePatientsDrawer);
				return this.$store?.state.updateDrawer;
			},
			set (val) {
				this.$store.commit('SET_UPDATE_DETAILS_DRAWER', val);
			},
		},
	},
	methods: {
		selectPatientDrawer (dat) {
			this.activePatientDetailsDrawer = dat;
		},
		clearDrawer () {
			this.$store.commit('SET_PATIENT_DETAILS_DRAWER', '');
			// this.activePatientDetailsDrawer = '';
		},
	},
  watch: {
    getPage2 (val) {
    },
  },
};
</script>
