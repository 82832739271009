<template>
	<v-card class="mx-auto" elevation="0">
		<v-card-title class="text-left card-header fixed-card-title2">{{ $t("add-note") }}
        <v-spacer></v-spacer>
        <v-icon class="white--text float-end mr-5" @click="closeDrawer">mdi-close</v-icon>
		</v-card-title>
		<v-card-text  class="px-0" :key="rerender">
		<v-textarea class="mt-2 px-2" :height="pageHeight(180)" solo no-resize v-model="medicalNote"></v-textarea>
    </v-card-text>
		<v-card-actions class="fixed-card-actions">
			<v-row class="mb-0">
				<v-col cols="6">
					<v-btn
            elevation="0"
						@click="closeDrawer"
            class="cancel-button-style capitalize-text"
					>
						{{ $t("cancel") }}
					</v-btn>
				</v-col>
				<v-col cols="6">
					<v-btn
            :disabled="medicalNote === ''"
						@click="updatePatientMetaData"
            class="save-button-style"
					>
						{{ $t("save") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>

import { closeBlack } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
export default {
  data () {
		return {
			closeBlack: closeBlack,
			medicalNote: '',
			rerender: 1,
      medicalNoteEmpty: true,
			isLoading: false,
		};
	},

	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			detailedUser: (state) => state.users.detailedUser,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
		}),
    textareaRows () {
      return this.$vuetify.breakpoint.lgAndUp ? 30 : 17;
    },
	},
	methods: {
    closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
		},
		async updatePatientMetaData () {
			const payloadBody = {
				appointmentId: null,
				patientId: this.detailedUser.userData.id,
				noteDescription: this.medicalNote,
				noteType: 1,
			};
			this.medicalNote = '';
			this.rerender = !this.rerender;
			if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				try {
					await this.$store.dispatch('appointments/addAppointmentNote', payloadBody);
					this.$store.commit('alerts/add', {
						id: 'addedUser',
						type: 'success',
						color: 'green',
						// message: res.msg,
						message: this.$t('noteAdded'),
					});
          this.$store.commit('appointments/CREATE_NOTE', true);
          this.$emit('false');
          this.closeDrawer();
          await this.$store.dispatch('alerts/clearWithDelay', this.delay);
				} catch (error) {
					this.$store.commit('alerts/add', {
						id: 'addedUser',
						type: 'error',
						color: 'main_red',
						// message: error.message,
						message: this.$t('noteFailed'),
					});
					await this.$store.dispatch('alerts/clearWithDelay', this.delay);
				}
        return await this.$store.dispatch('appointments/getNotesWithPatient', this.$route.params.id);
			}
		},
	},
};
</script>
