<template>
	<v-card elevation="0">
    <v-card-title class="text-left card-header fixed-card-title2">
			<v-row>
				<v-col cols="10">
					<v-toolbar-title>{{ $t("edit-treatments") }}</v-toolbar-title>
				</v-col>
        <v-col cols="2" class="text-right mt-n1">
          <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
        </v-col>
			</v-row>
		</v-card-title>
    <v-card-text class="px-0">
      <v-textarea placeholder="Edit Treatments" class="mt-2 px-2" :height="pageHeight(210)" solo v-model="treatments" no-resize></v-textarea>
    </v-card-text>
    <v-card-actions class="fixed-card-actions" >
			<v-row class="mb-0">
				<v-col cols="6">
					<v-btn
						@click="closeDrawer"
						elevation="0"
            class="capitalize-text cancel-button-style"
          >
						{{ $t("cancel") }}
					</v-btn>
				</v-col>
				<v-col cols="6">
					<v-btn
						@click="updatePatientMetaData"
            class="save-button-style"
					>
						{{ $t("save") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>

import { closeBlack, closeWhite } from '@/assets/index';
import { mapGetters, mapState } from 'vuex';
// import patientmetadata from '@/data/store/modules/patientmetadata';

export default {
	data () {
		return {
			closeBlack: closeBlack,
			treatments: '',
			closeWhite: closeWhite,
			isLoading: false,
		};
	},

	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			detailedUser: (state) => state.users.detailedUser,
		}),
    ...mapGetters({
      pageHeight: 'pageHeight',
    }),
    textareaRows () {
      return this.$vuetify.breakpoint.lgAndUp ? 30 : 19;
    },
	},

	async mounted () {
		this.treatments = this.getTreatments();
	},

	methods: {
		closeDrawer () {
			this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
			this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
		},

		getTreatments () {
			let parsedTreatments = this.detailedUser.userData.patientMetadata.prescriptions;
			parsedTreatments = parsedTreatments?.replace(/<\/p>/g, '');
			parsedTreatments = parsedTreatments?.replace(/<p>/g, '\n');
			return parsedTreatments;
		},

		stringifyTreatments () {
			let treatmentsString = '';
			const treatmentsData = this.treatments.split('\n');

			treatmentsData.forEach(allergy => {
				treatmentsString += '<p>' + allergy + '</p>';
			});

			return treatmentsString;
		},

		async updatePatientMetaData () {
			const metaDataBody = {
				userId: this.detailedUser.userData.id,
			};

			metaDataBody.prescriptions = this.stringifyTreatments();

			try {
				// eslint-disable-next-line func-call-spacing
				await this.$store.dispatch('patientmetadata/editPatientMetaData', metaDataBody).then(() => {
          this.$store.dispatch('users/getUserById', this.$route.params.id);
        });
				this.$store.commit('alerts/add', {
					id: 'addedUser',
					type: 'success',
					color: 'green',
					message: this.$t('Success'),
				});
				this.$emit('false');
				await this.$store.dispatch('alerts/clearWithDelay', this.delay);
				this.closeDrawer();
			} catch (error) {
				this.$store.commit('alerts/add', {
					id: 'addedUser',
					type: 'error',
					color: 'main_red',
					message: this.$t('failed'),
				});
			}
		},

	},

};
</script>
